import {
  BranchesOutlined,
  DesktopOutlined,
  MailOutlined,
  PartitionOutlined,
  PhoneOutlined,
  TableOutlined,
} from '@ant-design/icons'
import './App.css'
import { Card, Col, Layout, Row, Space } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import Menu from './components/menu'
import locales from './locales/locale'
import ram from './images/ram.png'
import ssd from './images/ssd.png'
import televisioni from './images/televisioni.png'
import pannelli from './images/pannelli.png'
import { useState } from 'react'
import { Fade, Slide } from 'react-awesome-reveal'

function App() {
  const [language, setLanguage] = useState(locales.getLanguage())

  return (
    <Layout>
      <Menu setLang={setLanguage} lang={language} />
      <Content style={{ zIndex: 1 }}>
        <div className="background">
          <Space
            className="primoParagrafo"
            direction="vertical"
            align="start"
            size="small"
          >
            {/* <h2>Basic desire</h2> */}
            <p>{locales.paragrafo1}</p>
          </Space>
          {/* <svg
            className="white-svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <path d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"></path>
          </svg> */}
        </div>
        <Row
          style={{ alignItems: 'baseline' }}
          className="card-container"
          gutter={[16, 16]}
        >
          <Col style={{ maxWidth: '100%' }} span={5}>
            <Slide direction="up">
              <Card
                title={
                  <Space
                    className="titolo-card"
                    align="center"
                    direction="vertical"
                  >
                    <BranchesOutlined />
                    <b className="bluScuro">
                      {locales.paragrafo2.riquadro1.titolo}
                    </b>
                  </Space>
                }
              >
                <span>{locales.paragrafo2.riquadro1.descrizione}</span>
              </Card>
            </Slide>
          </Col>
          <Col style={{ maxWidth: '100%' }} span={5}>
            <Slide direction="up" delay={100}>
              <Card
                title={
                  <Space
                    className="titolo-card"
                    align="center"
                    direction="vertical"
                  >
                    <PartitionOutlined />
                    <b className="bluScuro">
                      {locales.paragrafo2.riquadro2.titolo}
                    </b>
                  </Space>
                }
              >
                <span>{locales.paragrafo2.riquadro1.descrizione}</span>
              </Card>
            </Slide>
          </Col>
          <Col style={{ maxWidth: '100%' }} span={5}>
            <Slide direction="up" delay={200}>
              <Card
                title={
                  <Space
                    className="titolo-card"
                    align="center"
                    direction="vertical"
                  >
                    <DesktopOutlined />
                    <b className="bluScuro">
                      {locales.paragrafo2.riquadro3.titolo}
                    </b>
                  </Space>
                }
              >
                <span>{locales.paragrafo2.riquadro3.descrizione}</span>
              </Card>
            </Slide>
          </Col>
          <Col style={{ maxWidth: '100%' }} span={5}>
            <Slide direction="up" delay={300}>
              <Card
                title={
                  <Space
                    className="titolo-card"
                    align="center"
                    direction="vertical"
                  >
                    <TableOutlined />
                    <b
                      style={{
                        textWrap: 'balance',
                      }}
                      className="bluScuro"
                    >
                      {locales.paragrafo2.riquadro4.titolo}
                    </b>
                  </Space>
                }
              >
                <span>{locales.paragrafo2.riquadro4.descrizione}</span>
              </Card>
            </Slide>
          </Col>
        </Row>
        <Fade delay={500}>
          <div className="paragrafo">
            <div style={{ width: '90%' }}>
              <h1>{locales.paragrafo3.titolo}</h1>
              <p style={{ fontSize: '1vw' }}>
                {locales.paragrafo3.descrizione}
              </p>
            </div>
            <div
              className="pallini"
              style={{
                textAlign: 'center',
                width: '90%',
                display: 'flex',
              }}
            >
              <div className="box-pallino">
                <div style={{ placeContent: 'center', height: '8vw' }}>
                  <span className="dot">
                    <span>1</span>
                  </span>
                </div>
                <h2 style={{ color: '#b30000' }}>
                  {locales.paragrafo3Bis.riquadro1.titolo}
                </h2>
                <p style={{ fontSize: '1vw' }}>
                  {locales.paragrafo3Bis.riquadro1.descrizione}
                </p>
              </div>
              <div className="box-pallino">
                <div style={{ placeContent: 'center', height: '8vw' }}>
                  <span className="dot">
                    <span>2</span>
                  </span>
                </div>
                <h2 style={{ color: '#b30000' }}>
                  {locales.paragrafo3Bis.riquadro2.titolo}
                </h2>
                <p style={{ fontSize: '1vw' }}>
                  {locales.paragrafo3Bis.riquadro2.descrizione}
                </p>
              </div>
              <div className="box-pallino">
                <div style={{ placeContent: 'center', height: '8vw' }}>
                  <span className="dot">
                    <span>3</span>
                  </span>
                </div>
                <h2 style={{ color: '#b30000' }}>
                  {locales.paragrafo3Bis.riquadro3.titolo}
                </h2>
                <p style={{ fontSize: '1vw' }}>
                  {locales.paragrafo3Bis.riquadro3.descrizione}
                </p>
              </div>
              <div className="box-pallino">
                <div style={{ placeContent: 'center', height: '8vw' }}>
                  <span className="dot">
                    <span>4</span>
                  </span>
                </div>
                <h2 style={{ color: '#b30000' }}>
                  {locales.paragrafo3Bis.riquadro4.titolo}
                </h2>
                <p style={{ fontSize: '1vw' }}>
                  {locales.paragrafo3Bis.riquadro4.descrizione}
                </p>
              </div>
            </div>
            {/* <img alt="By Karolina Grabowska" src={team} /> */}
          </div>
        </Fade>

        <div
          className="paragrafo reverse sfondoRosso"
          style={{
            height: '120vh',
          }}
        >
          <Slide direction="up">
            <div>
              <h1>{locales.paragrafo4.titolo1}</h1>
              <p>{locales.paragrafo4.descrizione1}</p>
              <h1>{locales.paragrafo4.titolo2}</h1>
              <p>{locales.paragrafo4.descrizione2}</p>
            </div>
          </Slide>
          <Fade>
            <div>
              <img
                alt="By AI"
                style={{
                  borderRadius: '1vw',
                  position: 'relative',
                  top: '5vw',
                }}
                width={'50%'}
                src={televisioni}
              />
              <img
                style={{
                  left: '-5vw',
                }}
                className="img-style"
                alt="By Kelly"
                width={'50%'}
                src={pannelli}
              />
            </div>
          </Fade>
        </div>

        <div
          className="paragrafo"
          style={{
            height: '120vh',
            overflow: 'hidden',
          }}
        >
          <Slide direction="up">
            <div>
              <h1>{locales.paragrafo5.riquadro1.titolo}</h1>
              <p>{locales.paragrafo5.riquadro1.descrizione}</p>
              <h1>{locales.paragrafo5.riquadro2.titolo}</h1>
              <p>{locales.paragrafo5.riquadro2.descrizione}</p>
            </div>
          </Slide>
          <Fade>
            <div>
              <img
                alt="By Valentine Tanasovich"
                style={{
                  borderRadius: '1vw',
                  position: 'relative',
                  top: '5vw',
                  left: '5vw',
                }}
                width={'50%'}
                src={ram}
              />
              <img className="img-style" alt="By AI" width={'50%'} src={ssd} />
            </div>
          </Fade>
        </div>
      </Content>
      <Footer>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <b>{locales.contact}</b>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <Space
              style={{
                borderRight: '1px #ffffff3d solid',
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <PhoneOutlined />
              <span>+359 877861509</span>
            </Space>
          </Col>
          <Col>
            <Space
              style={{
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <MailOutlined />
              <a href="mailto:info@basicdesire.eu" style={{ color: 'white' }}>
                info@basicdesire.eu
              </a>
            </Space>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center', marginTop: '1vw' }}>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'white', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.termsfeed.com/live/81d6d201-cc47-4e38-8e1b-ff4b535fa8c4"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Col>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'white', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.cookiepolicygenerator.com/live.php?token=3pbkndxafu7oWdzjoA3BkM1ZXGBlCxEo"
              rel="noreferrer"
            >
              Cookie policy
            </a>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}

export default App
