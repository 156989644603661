import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en: {
    paragrafo1:
      'Basic desire is a leading wholesaler in the consumer electronics sector, specializing in a comprehensive range of high-demand products like RAM cards, SSDs, televisions, and more. We cater to retailers and businesses looking to stock the latest in technology at competitive prices. With strong partnerships with major electronics manufacturers, Basic desire ensures a steady supply of the newest and most sought-after tech products. Our dedication to customer service and quick turnaround times makes us a preferred partner for clients aiming to meet the dynamic needs of tech-savvy consumers.',
    paragrafo2: {
      riquadro1: {
        titolo: 'RAM Cards',
        descrizione:
          'We offer a wide range of RAM cards to enhance the performance of computing devices, ensuring a smooth and responsive experience.',
      },
      riquadro2: {
        titolo: 'SSDs',
        descrizione:
          'Our SSDs provide superior speed and reliability compared to traditional HDDs, optimising data loading and access.',
      },
      riquadro3: {
        titolo: 'Televisions',
        descrizione:
          'Choose from a variety of high-definition and ultra-high-definition TVs for an immersive visual experience in the world of entertainment.',
      },
      riquadro4: {
        titolo: 'Other Technological Products',
        descrizione:
          'In addition to our core products, we offer a wide range of accessories and electronic devices to meet the ever-growing needs of our customers.',
      },
    },
    paragrafo3: {
      titolo:
        'Basic Desire is the benchmark for innovative and customised solutions for your business.',
      descrizione:
        "Our co-operative is committed to impeccable, tailor-made service, ensuring that every product, whether it is a consumer phone device, SSD, RAM, television, or building materials, reaches your destination at exactly the right time, with the desired quality and quantity. Our main goal is to exceed our customers' expectations and contribute to the success of their businesses.",
    },
    paragrafo3Bis: {
      riquadro1: {
        titolo: 'Generating Opportunities',
        descrizione: 'Actively seeking new market and business opportunities.',
      },
      riquadro2: {
        titolo: 'Snapshot Offers',
        descrizione:
          'Ready to provide detailed and customised quotes instantly.',
      },
      riquadro3: {
        titolo: 'Planning and Delivery',
        descrizione:
          'We guarantee fast and reliable delivery of your products.',
      },
      riquadro4: {
        titolo: 'Monitoring & Analysis',
        descrizione: 'We constantly monitor our operations.',
      },
    },
    paragrafo4: {
      titolo1: 'Buying and Selling Technology Products',
      descrizione1:
        "Basic desire is the perfect solution for your technology needs. We specialise in the purchase and sale of a wide range of consumer electronics products, including SSDs, RAM, televisions, and building materials.We guarantee quality and innovation to meet our customers' needs, offering state-of-the-art products at competitive prices.",
      titolo2: 'Preparation, Packaging and Marketing Services',
      descrizione2:
        'At Basic desire, excellence and innovation drive everything we do. Our range of services includes the selection, preparation and packaging of high-quality technological products. Every step of the process is carefully taken to ensure optimal performance and maximum customer satisfaction. Discover our wide range of products, designed to meet the evolving needs of technology consumers.',
    },

    paragrafo5: {
      riquadro1: {
        titolo: 'Wholesale of Electronic Products',
        descrizione:
          'Basic desire stands out in the wholesale of consumer electronics products. Our wide range of items includes RAM cards, SSDs, televisions and more, all in high demand in the market. Thanks to our strong partnerships with leading electronics manufacturers, we guarantee a constant supply of the latest and most sought-after products. Our priority is customer satisfaction, which is why we offer impeccable customer service and fast delivery times.',
      },
      riquadro2: {
        titolo: 'Custom Assembly and Packaging Services',
        descrizione:
          'Basic desire offers customised solutions for your product assembly and packaging needs. We are committed to providing efficient and accurate services to optimise the logistics and presentation of your items. Our focus on quality and speed of execution makes us a reliable partner for companies seeking to meet the changing needs of technology consumers.',
      },
    },
    contact: 'Contact us',
  },
  bg: {
    paragrafo1:
      'Basic desire е водещ търговец на едро в сектора на потребителската електроника, специализиран в широка гама от продукти с високо търсене, като карти RAM, SSD, телевизори и др. Ние обслужваме търговци на дребно и предприятия, които искат да се снабдят с най-новите технологии на конкурентни цени. Със силните си партньорства с големи производители на електроника Basic desire осигурява постоянни доставки на най-новите и най-търсените технологични продукти. Нашата отдаденост на обслужването на клиентите и бързите срокове за изпълнение на поръчките ни правят предпочитан партньор за клиенти, които се стремят да посрещнат динамичните нужди на технически грамотните потребители.',
    paragrafo2: {
      riquadro1: {
        titolo: 'Карти RAM',
        descrizione:
          'Предлагаме широка гама от RAM карти за подобряване на производителността на компютърните устройства, като осигуряваме плавна и бърза реакция.',
      },
      riquadro2: {
        titolo: 'SSD',
        descrizione:
          'Нашите SSD дискове осигуряват по-висока скорост и надеждност в сравнение с традиционните твърди дискове, като оптимизират зареждането на данни и достъпа до тях.',
      },
      riquadro3: {
        titolo: 'Телевизори',
        descrizione:
          'Изберете от разнообразни телевизори с висока и ултрависока разделителна способност, за да се потопите в света на развлеченията.',
      },
      riquadro4: {
        titolo: 'Basic Desire е еталон за иновативни и персонализирани решения за вашия бизнес.',
        descrizione:
          'В допълнение към основните ни продукти предлагаме широка гама от аксесоари и електронни устройства, за да отговорим на постоянно нарастващите нужди на нашите клиенти.',
      },
    },
    paragrafo3: {
      titolo:
        'В основата на Factory Solution са персонализираните търговски решения.',
      descrizione:
        'Нашата кооперация се ангажира с безупречно обслужване по поръчка, като гарантира, че всеки продукт, независимо дали става въпрос за устройство за телефон, SSD, RAM, телевизор или строителни материали, ще достигне до вашата дестинация в точното време, с желаното качество и количество. Нашата основна цел е да надминем очакванията на клиентите си и да допринесем за успеха на техния бизнес.',
    },
    paragrafo3Bis: {
      riquadro1: {
        titolo: "Генериране на възможности",
        descrizione: "Активно търсене на нови пазарни и бизнес възможности.",
      },
      riquadro2: {
        titolo: "Снимка на офертите",
        descrizione:
          'Готови сте незабавно да предоставите подробни и персонализирани оферти.',
      },
      riquadro3: {
        titolo: "Планиране и доставка",
        descrizione:
          'Гарантираме бърза и надеждна доставка на вашите продукти.',
      },
      riquadro4: {
        titolo: "Мониторинг и анализ",
        descrizione: "Непрекъснато наблюдаваме нашите операции.",
      },
    },
    paragrafo4: {
      titolo1: 'Покупка и продажба на технологични продукти',
      descrizione1:
        "Basic desire е идеалното решение за вашите технологични нужди. Специализирани сме в покупката и продажбата на широка гама продукти на потребителската електроника, включително SSD, RAM, телевизори и строителни материали.Гарантираме качество и иновации, за да отговорим на нуждите на нашите клиенти, като предлагаме най-съвременни продукти на конкурентни цени.",
      titolo2: 'Услуги по подготовка, опаковане и маркетинг',
      descrizione2:
        'В Basic desire върховите постижения и иновациите са двигател на всичко, което правим. Нашата гама от услуги включва подбор, подготовка и опаковане на висококачествени технологични продукти. Всяка стъпка от процеса е внимателно предприета, за да се осигури оптимална производителност и максимално удовлетворение на клиентите. Открийте нашата широка гама от продукти, създадени да отговорят на променящите се нужди на потребителите на технологични продукти.',
    },

    paragrafo5: {
      riquadro1: {
        titolo: 'Търговия на едро с електронни продукти',
        descrizione:
          'Основното желание се откроява в търговията на едро с продукти на потребителската електроника. Широката ни гама от артикули включва карти RAM, SSD, телевизори и други, които са много търсени на пазара. Благодарение на силните ни партньорства с водещи производители на електроника, ние гарантираме постоянни доставки на най-новите и най-търсени продукти. Наш приоритет е удовлетворението на клиентите, поради което предлагаме безупречно обслужване и бързи срокове за доставка.',
      },
      riquadro2: {
        titolo: 'Услуги за монтаж и опаковане по поръчка',
        descrizione:
          'Basic desire предлага персонализирани решения за вашите нужди от сглобяване и опаковане на продукти. Ние се стремим да предоставяме ефективни и точни услуги за оптимизиране на логистиката и представянето на вашите продукти. Фокусът ни върху качеството и бързината на изпълнение ни превръща в надежден партньор за компании, които се стремят да отговорят на променящите се нужди на потребителите на технологии.',
      },
    },
    contact: 'Свържете се с нас',
  },
})

export default locales
